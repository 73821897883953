<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationObserver ref="observer">
      <v-layout column wrap>
        <v-card-title
          class="font-weight-black pl-3 mb-4"
          style="font-size:32px"
          >{{ $t('link.editLoginUserInfo') }}</v-card-title
        >
        <v-row class="ml-8 mb-4">
          <p class="title font-weight-black">
            {{ $t('label.editUserInfo.loginId') }}
          </p>
          <p class="title">
            {{ loginUserInfo.loginId }}
          </p>
        </v-row>
        <!-- ユーザー名 -->
        <v-row class="ml-8">
          <p class="title font-weight-black mb-0">
            {{ $t('label.editUserInfo.userName') }}
          </p>
          <p class="title mb-0">
            {{ loginUserInfo.userName }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-4 pt-1">
          <p>
            {{ $t('description.editUserInfo.userName') }}
          </p>
        </v-row>
        <!--  メールアドレス -->
        <v-row class="ml-8">
          <p class="title my-auto font-weight-black">
            {{ $t('label.editUserInfo.userMail') }}
            <v-chip
              class="ma-2 font-weight-black"
              color="next"
              dark
              label
              small
              >{{ $t('label.required') }}</v-chip
            >
          </p>
        </v-row>
        <v-row class="ml-8 pt-1">
          <p>
            {{ $t('description.editUserInfo.mailAddress') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-4">
          <v-flex md4>
            <ValidationProvider
              :rules="
                `required|noSpace|pattern:mailAddress|max:${MaxLength.MailAddress}`
              "
              immediate
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="loginUserInfo.userMail"
                hide-details
                :outlined="true"
              />
              <ValidationError class="ma-0" :error="errors[0]" />
            </ValidationProvider>
          </v-flex>
        </v-row>
        <!-- アラートメールの設定 -->
        <v-row v-if="canShow('alertMailFlg')" class="ml-8">
          <v-col class="pl-0 pb-0">
            <p class="title my-auto font-weight-black">
              {{ $t('label.editUserInfo.alertMailSetting') }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="canShow('alertMailFlg')" class="ml-8">
          <p class="pt-1">
            {{ $t('description.editUserInfo.alertMail') }}
          </p>
        </v-row>
        <v-row v-if="canShow('alertMailFlg')" class="ml-8 mb-4">
          <v-switch
            class="my-0"
            v-model="loginUserInfo.alertMailFlg"
            color="next"
            :label="alertMailFlgToString()"
          ></v-switch>
        </v-row>
        <v-row class="ml-8 mt-4">
          <v-btn
            class="title mr-5"
            color="back"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickBackHome()"
            >{{ $t('button.cancel') }}</v-btn
          >
          <v-btn
            class="title ml-5"
            color="next"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="openConfirmDialog()"
            >{{ $t('button.update') }}</v-btn
          >
        </v-row>
        <CompletedDialog
          @onSuccess="onSuccessUpdateLoginUserInfo"
          ref="completedDialog"
        ></CompletedDialog>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
        <!-- 更新確認 -->
        <SimpleDialog
          :showDialog="showConfirmDialog"
          :title="$t('title.agency.userEdit')"
          :text="null"
          :subText="null"
          :multiLineText="null"
          :confirmText="$t('description.updateLoginUserInfo')"
          :negativeButtonTitle="$t('button.cancel')"
          :positiveButtonTitle="$t('button.update')"
          :onClickNegativeButton="closeConfirmDialog"
          :onClickPositiveButton="onClickUpdate"
        />
      </v-layout>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import { MaxLength } from '@/lib/const';
import { Role } from '@/lib/role';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';
import { getLoginUser, putLoginUser } from '@/apis/agency/users';

export default {
  name: 'EditUserInfo',
  components: {
    CompletedDialog,
    ErrorDialog,
    SimpleDialog,
    ValidationProvider,
    ValidationObserver,
    ValidationError,
  },
  data() {
    return {
      // ログインユーザー情報
      loginUserInfo: {},
      MaxLength: MaxLength,
      showConfirmDialog: false,
    };
  },
  computed: {},
  async mounted() {
    // ユーザーの取得
    await this.fetchUsers();
  },
  methods: {
    ...mapActions('user', ['setUserDetail']),
    ...mapGetters('user', ['userDetail']),

    // 表示するかどうか
    canShow(key) {
      return Role.canShow(this.userDetail().role, key);
    },

    async fetchUsers() {
      // ログインユーザー情報の取得
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.$set(this, 'loginUserInfo', loginUserInfo);
    },

    alertMailFlgToString() {
      return this.loginUserInfo.alertMailFlg
        ? this.$t('button.switchOn')
        : this.$t('button.switchOff');
    },

    onClickBackHome() {
      this.$router.push('/Home');
    },
    async openConfirmDialog() {
      // バリデーションチェック
      if (await this.$refs.observer.validate()) {
        this.$set(this, 'showConfirmDialog', true);
      }
    },
    closeConfirmDialog() {
      this.$set(this, 'showConfirmDialog', false);
    },
    async onClickUpdate() {
      // 編集項目の保持
      const condition = {
        ...(this.loginUserInfo.userMail !== this.userDetail().userMail && {
          userMail: this.loginUserInfo.userMail,
        }),
        ...(this.loginUserInfo.alertMailFlg !==
          this.userDetail().alertMailFlg && {
          alertMailFlg: this.loginUserInfo.alertMailFlg,
        }),
      };
      if (!Object.keys(condition).length) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          this.$t('error.userUpdateNonChange')
        );
        this.$set(this, 'showConfirmDialog', false);
        return;
      }

      // DB更新
      const result = await putLoginUser(condition).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          this.$t('error.userUpdateBadRequest')
        );
      });

      // ユーザーが編集できなかった場合、以降の処理を中止する
      if (!result) return;

      this.$set(this, 'showConfirmDialog', false);
      await this.fetchUsers();

      // ストア情報の更新
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.setUserDetail(loginUserInfo);

      this.$refs.completedDialog.open(
        this.$t('title.agency.editUserInfoResult'),
        this.$t('success.userInfoUpdated')
      );
    },
    onSuccessUpdateLoginUserInfo() {
      this.$router.push('/Home');
    },
    onErrorUpdate() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.editUserInfoResult'),
        this.$t('error.updateUserInfoFailed')
      );
    },
  },
};
</script>
