var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "font-weight-black pl-3 mb-4",
                  staticStyle: { "font-size": "32px" }
                },
                [_vm._v(_vm._s(_vm.$t("link.editLoginUserInfo")))]
              ),
              _c("v-row", { staticClass: "ml-8 mb-4" }, [
                _c("p", { staticClass: "title font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.loginId")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.loginId) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c("p", { staticClass: "title font-weight-black mb-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.userName")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title mb-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.userName) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8 mb-4 pt-1" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("description.editUserInfo.userName")) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c(
                  "p",
                  { staticClass: "title my-auto font-weight-black" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.editUserInfo.userMail")) +
                        "\n          "
                    ),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-2 font-weight-black",
                        attrs: { color: "next", dark: "", label: "", small: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("label.required")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-row", { staticClass: "ml-8 pt-1" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("description.editUserInfo.mailAddress")) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "ml-8 mb-4" },
                [
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules:
                            "required|noSpace|pattern:mailAddress|max:" +
                            _vm.MaxLength.MailAddress,
                          immediate: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("v-text-field", {
                                  attrs: { "hide-details": "", outlined: true },
                                  model: {
                                    value: _vm.loginUserInfo.userMail,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.loginUserInfo,
                                        "userMail",
                                        $$v
                                      )
                                    },
                                    expression: "loginUserInfo.userMail"
                                  }
                                }),
                                _c("ValidationError", {
                                  staticClass: "ma-0",
                                  attrs: { error: errors[0] }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.canShow("alertMailFlg")
                ? _c(
                    "v-row",
                    { staticClass: "ml-8" },
                    [
                      _c("v-col", { staticClass: "pl-0 pb-0" }, [
                        _c(
                          "p",
                          { staticClass: "title my-auto font-weight-black" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("label.editUserInfo.alertMailSetting")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canShow("alertMailFlg")
                ? _c("v-row", { staticClass: "ml-8" }, [
                    _c("p", { staticClass: "pt-1" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("description.editUserInfo.alertMail")) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.canShow("alertMailFlg")
                ? _c(
                    "v-row",
                    { staticClass: "ml-8 mb-4" },
                    [
                      _c("v-switch", {
                        staticClass: "my-0",
                        attrs: {
                          color: "next",
                          label: _vm.alertMailFlgToString()
                        },
                        model: {
                          value: _vm.loginUserInfo.alertMailFlg,
                          callback: function($$v) {
                            _vm.$set(_vm.loginUserInfo, "alertMailFlg", $$v)
                          },
                          expression: "loginUserInfo.alertMailFlg"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "ml-8 mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "title mr-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "back", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.onClickBackHome()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "title ml-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "next", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.openConfirmDialog()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.update")))]
                  )
                ],
                1
              ),
              _c("CompletedDialog", {
                ref: "completedDialog",
                on: { onSuccess: _vm.onSuccessUpdateLoginUserInfo }
              }),
              _c("ErrorDialog", { ref: "errorDialog" }),
              _c("SimpleDialog", {
                attrs: {
                  showDialog: _vm.showConfirmDialog,
                  title: _vm.$t("title.agency.userEdit"),
                  text: null,
                  subText: null,
                  multiLineText: null,
                  confirmText: _vm.$t("description.updateLoginUserInfo"),
                  negativeButtonTitle: _vm.$t("button.cancel"),
                  positiveButtonTitle: _vm.$t("button.update"),
                  onClickNegativeButton: _vm.closeConfirmDialog,
                  onClickPositiveButton: _vm.onClickUpdate
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }